





































































































































































import { Form } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import FooterMini from "@/components/FooterMini.vue";
import { login,getEmailCode,getPhoneCode,forgetPassReset } from "@/api";


@Component({
  components: {
    FooterMini,
  },
})

export default class Login extends Vue {
  
  loginForm = {
    username: "",
    password: "",
    code:111111,
    uuid:"111",
    subSystem:1,
    rememberMe: false,
    captcha:111111
  };

  forgetPassEmailRules= {
    email: [
      { required: true, message: "请输入邮箱", trigger: "change" },
      { validator:this.validateEmail , trigger: "blur" },
    ],
    emailNewPass: [
      { required: true, message: "请输入密码", trigger: "change" },
      { validator: this.validatePassword , trigger: "blur" },
      ],
    emailVerifyCode: [
      { required: true, message: "请输入邮箱验证码", trigger: "change" },
      { len: 6, message: "邮箱验证码位数不正确", trigger: "blur" },
    ],
  };

  forgetPassMobileRules= {
    mobile: [
      { required: true, message: "请输入手机号码", trigger: "change" },
      { len: 11, message: "长度需 11 个字符", trigger: "blur" },
      { validator:this.validatePhone , trigger: "blur" },
    ],
    mobileNewPass: [
      { required: true, message: "请输入密码", trigger: "change" },
      { validator: this.validatePassword , trigger: "blur" },
      ],
    mobileVerifyCode: [
      { required: true, message: "请输入手机验证码", trigger: "change" },
      { len: 6, message: "手机验证码位数不正确", trigger: "blur" },
    ],
  };

  rules = {
    username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
  };

  dialogForgetPassVisible = false;
  forgetPass = {
    mobile :"",
    mobileVerifyCode:"",
    mobileNewPass:"",
    email:"",
    emailVerifyCode:"",
    emailNewPass:"",
    type:""

  }
  emailButtonDisable = false;
  emailSecond = 0;
  mobileButtonDisable = false;
  mobileSecond = 0;

  activeName = 'mobile'
  handleClick(tab, event) {
      console.log(tab.paneName);
  }
  
  validateForm(): void {
    (this.$refs["loginForm"] as Form).validate((valid: boolean) => {
      if (valid) {
        this.submitForm();
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  };
     created () {
        // // 在页面加载时从cookie获取登录信息
        // let account = this.getCookie("account")
        // let password = this.getCookie("password")
        // // 如果存在赋值给表单，并且将记住密码勾选
        // if(account){
        //     this.loginForm.username = account
        //     this.loginForm.password = password
        //     this.loginForm.rememberMe = true
        // }
    };
  rememberMeChange(){
    
    if(this.loginForm.rememberMe){
      let remberAccount = {};
      remberAccount['userName'] = this.loginForm.username;
      remberAccount['passWord'] = this.loginForm.password;
      remberAccount['rememberMe'] = this.loginForm.rememberMe;
      window.localStorage.setItem("remberAccount",JSON.stringify(remberAccount));
    }else{
      window.localStorage.removeItem("remberAccount");

    }
  };
  submitForm(): void {
    login(this.loginForm).then(res => {
        if(res.status == 200){
          if(res.data.code == 200){
            // this.$message.success("登录成功");
            window.localStorage.setItem("Authorization",res.data.data.access_token);
            this.$router.replace({ name: 'Home' })
            this.rememberMeChange();
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        }      
      
    }).catch(() => {
      this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      console.log('error')
    })
  };
   forgetPassReset(key:string,type:string,newPass:string,verifyCode:string): void {
     let data = {
      key : key,
      type : type,
      newPass : newPass, 
      verifyCode : verifyCode,
     }
    forgetPassReset(data).then(res => {
        if(res.status == 200){
          if(res.data.code == 200){
            this.dialogForgetPassVisible = false;
            this.$message.success("重置密码成功");
            // window.localStorage.setItem("Authorization",res.data.data.access_token);
            // this.$router.replace({ name: 'Home' })
            // this.rememberMeChange();
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        }      
      
    }).catch(() => {
      this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      console.log('error')
    })
  };
  mounted(){
     if(window.localStorage.getItem("remberAccount")){
       let remberAccount = JSON.parse(window.localStorage.getItem("remberAccount"));
       this.loginForm.username = remberAccount.userName;
       this.loginForm.password = remberAccount.passWord;
       this.loginForm.rememberMe = remberAccount.rememberMe ? remberAccount.rememberMe : false;
     }
     
  }
  getPhoneCode(): void {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (this.forgetPass.mobile == "" || this.forgetPass.mobile == undefined || this.forgetPass.mobile == null) {
        this.$message.error("手机不能为空");
        return;
    }
    if (this.forgetPass.mobile!= '' && !reg.test(this.forgetPass.mobile)) {
        this.$message.error("手机格式不正确");
        return;
    }
    getPhoneCode(this.forgetPass.mobile)
      .then((res) => {
      
        this.mobileSecond = 60;
        this.mobileButtonDisable = true;
        var smsTimer = setInterval(() => {
          this.mobileSecond = this.mobileSecond - 1;
          if (this.mobileSecond <= 0) {
            this.mobileButtonDisable = false;
            clearInterval(smsTimer);
          }
        }, 1000);
      })
      .catch(() => {
        console.log("error test");
      });
  }
    //获取验证码
  getEmailCode(): void {
      const regEmail =  /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (this.forgetPass.email == "" || this.forgetPass.email == undefined || this.forgetPass.email == null) {
          this.$message.error("邮箱不能为空");
          return;
      }
      if (this.forgetPass.email!= '' && !regEmail.test(this.forgetPass.email)) {
          this.$message.error("邮箱格式不正确");
          return;
      }
      getEmailCode(this.forgetPass.email)
        .then((res) => {
          // console.log(res);
          this.emailSecond = 60;
          this.emailButtonDisable = true;
          var smsTimer = setInterval(() => {
            this.emailSecond = this.emailSecond - 1;
            if (this.emailSecond <= 0) {
              this.emailButtonDisable = false;
              clearInterval(smsTimer);
            }
          }, 1000);
        })
        .catch(() => {
          console.log("error test");
        });
  }

  validateEmail(rule: any, value: string, callback: any) {
    const reg =  /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
    if (value == "" || value == undefined || value == null) {
      callback();
    } else {
      if (!reg.test(value) && value != "") {
        callback(new Error("请输入正确的邮箱"));
      } else {
        callback();
      }
    }
  }
  
  validatePassword(rule: any, value: string, callback: any): void {
    if (value.length < 6 || value.length > 10) {
      callback(new Error("密码长度在6-10位之间"));
    } else {
      callback();
    }
  }
  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^[1][3-9][0-9]{9}$/;
    if (value == "" || value == undefined || value == null) {
      callback();
    } else {
      if (!reg.test(value) && value != "") {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    }
  }
  validateEmailForm(): void {
    (this.$refs["forgetPassEmailForm"] as Form).validate((valid: boolean) => {
      if (valid) {
        this.forgetPassReset(this.forgetPass.email,"email",this.forgetPass.emailNewPass,this.forgetPass.emailVerifyCode);
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  validateMobileForm(): void {
    (this.$refs["forgetPassMobileForm"] as Form).validate((valid: boolean) => {
      if (valid) {
        this.forgetPassReset(this.forgetPass.mobile,"mobile",this.forgetPass.mobileNewPass,this.forgetPass.mobileVerifyCode);
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
